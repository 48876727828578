import React, { useEffect, useState } from 'react';
import { isEmptyObject, handleCloseTooltip } from 'helpers/utils';
import ButtonFireFocus from 'components/Buttons/Button/ButtonFire';
import { svg, png } from 'lib/svgList';
import { useDispatch, useSelector } from 'react-redux';
import GenericAccordion from 'components/GenericAccordion';
import { color } from 'styles/Theme';
import SwitchToggle from 'components/Buttons/SwitchToggleGeneral';
import { addVectorPoint } from 'store/actions/layersAction/addPointLayerOnMap/addPointLayerOnMap';
import { removeLayerFromMap, setActivatedLayers } from 'store/actions/mapActions';
import { StyleLayers } from 'components/Map/Styles/js/StyleLayers';
import BarGraphTotalLast from '../../Graphs/FireGraph/FireBarGraph';
import FireMonthLineGraph from '../../Graphs/FireGraph/FireMonthLineGraph';
import RiskFireData from '../../Graphs/FireGraph/RiskFire';
import * as S from './styled';

export default function FireAlert() {
	const dispatch = useDispatch();
	const tiSelectedRedux = useSelector((state) => state.tiSelectedTabTi.tiSelected);
	const { data: focusFire } = useSelector((state) => state.fireLast30days);
	const [buttonNameActive, setButtonNameActive] = useState('button30dias');
	const [toggle, setToggle] = useState(false);

	const [data, setData] = useState([]);

	const objPointConfig = {
		nameVector: 'tiFireAlert',
		label: 'Focos de calor - NASA',
		zIndex: 96000,
		icon: png.fogoOrange,
		style: [StyleLayers.pointBackground, StyleLayers.focoCalor],
		onVisiable: toggle
	};

	// esse titulo vai para as props do tooltip
	const title = 'Focos de calor - NASA';

	// coloca titulo nos focos para aparecer no tooltip
	useEffect(() => {
		if (focusFire.length > 0) {
			const newData = focusFire.map((item) => ({ ...item, title }));
			setData(newData);
		}
	}, [focusFire]);

	// add layer ao mapa quando o toggle é true e remove a layer quando false (e também dispara setActivatedLayers, pra remover a layer da legenda)
	useEffect(() => {
		dispatch(addVectorPoint(data, objPointConfig));
		if (!toggle) {
			handleCloseTooltip();
		}
	}, [toggle]);

	// remove layer quando comp é destruido
	useEffect(
		() => () => {
			removeLayerFromMap(objPointConfig.nameVector);
			dispatch(setActivatedLayers());
		},
		[]
	);

	return (
		<GenericAccordion
			imgTitle={svg.icon.fogoOrange}
			title="Alertas de Fogo"
			widthIconTitleAccordion={1.2}
			styleTitle={{
				color: `${color.interface.darkGray}`,
				fontWeight: '300'
			}}
			onClick={() => {
				if (!isEmptyObject(tiSelectedRedux) && tiSelectedRedux.value !== 0);
			}}
		>
			<S.container>
				<S.boxToggle>
					<SwitchToggle
						isChecked={toggle}
						setIsChecked={data.length > 0 ? setToggle : () => {}} // caso o dado seja vazio, nao altorizar alterar toggle
					/>
					{data.length === 0 ? (
						<p>
							<b>Não há registro</b> de foco de calor nos últimos 30 dias.
						</p>
					) : (
						<p>Mostrar alertas de foco de calor nos últimos 30 dias no mapa.</p>
					)}
				</S.boxToggle>

				{/* Risco de fogo */}
				<S.containerAccordionText style={{ marginTop: 10 }}>
					<h4>RISCO DE FOGO</h4>
					<p>Previsão para os próximos 3 dias.</p>
					<RiskFireData />
					<p className="p-end-accordion-text">
						<b style={{ marginRight: 2 }}>FONTE:</b> INPE
					</p>
				</S.containerAccordionText>

				<S.containerAccordionText>
					<h4>Total de focos atuais em comparação a anos anteriores</h4>
					<p>(Área Queimada (ha) X mês)</p>
				</S.containerAccordionText>
				<ButtonFireFocus buttonNameActive={buttonNameActive} setButtonNameActive={setButtonNameActive} />
				<S.containerGraphFireAlert>
					{buttonNameActive === 'button30dias' ? <BarGraphTotalLast /> : null}
					{buttonNameActive === 'buttonPorMes' ? <FireMonthLineGraph /> : null}
					{/* {buttonNameActive === 'buttonRiskFire' ? <RiskFireData /> : null} */}
				</S.containerGraphFireAlert>
			</S.container>
		</GenericAccordion>
	);
}
